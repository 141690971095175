import React from "react";
import {BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home"
import Produtos from "./pages/Produtos"
import Contacto from "./pages/Contacto"
import Footer from "./components/Footer"

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/produtos" element={<Produtos />} />
          <Route path="/contacto" element={<Contacto />} />
        </Routes>
        {<Footer />}
      </div>
    </Router>
  );
}

export default App;