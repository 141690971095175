import React from "react";
import { NavLink } from 'react-router-dom';

import logoImalusit from "../assets/img/logoImalusit.png";


const Header = () => {
    return (
        <header className="headerContainer" style={{ backgroundColor: '#00866e', maxWidth: '1280px', margin: 'auto' }}>
            <div className="container d-flex justify-content-between align-items-center p-3">
                <img 
                    src={logoImalusit} 
                    alt="Logo" 
                    className="logo" 
                    style={{ height: '60px' }} 
                />

                <nav className="navbar">
                    <ul className="navbar-nav d-flex flex-row fw-bold">
                        <li className="nav-item mx-3">
                            <NavLink 
                                to="/" 
                                className={({ isActive }) => `nav-link text-white ${isActive ? 'bg-danger' : ''}`}
                                style={{ paddingLeft: '20px', paddingRight: '20px', borderRadius: '5px' }}
                            >
                                Home
                            </NavLink>
                        </li>
                        <li className="nav-item mx-3">
                            <NavLink 
                                to="/Contacto" 
                                className={({ isActive }) => `nav-link text-white ${isActive ? 'bg-danger' : ''}`}
                                style={{ paddingLeft: '10px', paddingRight: '10px', borderRadius: '5px' }}
                            >
                                Contacto
                            </NavLink>
                        </li>
                        <li className="nav-item mx-3">
                            <NavLink 
                                to="/Produtos" 
                                className={({ isActive }) => `nav-link text-white ${isActive ? 'bg-danger' : ''}`}
                                style={{ paddingLeft: '10px', paddingRight: '10px', borderRadius: '5px' }}
                            >
                                Produtos
                            </NavLink>
                        </li>
                    </ul>
                </nav>


                <form className="d-flex" >
                    <input
                        type="search"
                        placeholder="Buscar..."
                        className="form-control me-2 rounded-pill"
                        style={{ height: '35px' }}
                    />
                </form>
            </div>
        </header>
    );
};

export default Header;