import React, { useState, useEffect  } from 'react';
import { productImages } from '../components/productImages';
import '../styles/home.css'


const Produtos = () => {
  // State para a imagem selecionada
  const [selectedImage, setSelectedImage] = useState(null);

  // Função para selecionar a imagem
  const handleImageClick = (image) => {
    setSelectedImage(image); // Armazena o objeto completo
  };

  // Função para fechar a imagem
  const handleClose = () => {
    setSelectedImage(null); 
  };

  // Hook para fechar com a tecla ESC
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        handleClose(); 
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Limpeza do event listener ao desmontar o componente
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div style={{ maxWidth: '1280px', margin: 'auto', padding: '0', backgroundColor: 'white' }}>
      {/* Grid de Imagens */}
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 295px)', 
          gap: '20px', 
          margin: '0 20px', 
          justifyItems: 'center', 
          justifyContent: 'center',
          alignItems: 'center', 
          backgroundColor: 'white',
          
        }}
      >
        {productImages.map((image) => {

          return (
            <div
              key={image.id}
              onClick={() => handleImageClick(image)}
              style={{
                position: 'relative',
                cursor: 'pointer',
                height: '600px',
                overflow: 'hidden',
                /*boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.3)',*/
                borderRadius: '8px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <img
                src={image.src}
                alt={image.alt}
                style={{
                  width: '295px', 
                  height: 'auto', 
                  borderRadius: '8px',
                  backgroundColor: 'white',
                  boxSizing: 'border-box',
                  
                }}
              />
              <p style={{
                position: 'absolute',
                bottom: '0',
                left: '0',
                width: '100%',
                padding: '5px',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: 'white',
                fontSize: '14px',
                textAlign: 'center',
              }}>{image.title}</p>
            </div>
          );
        })}
      </div>

      {/* Imagem Selecionada Maior */}
      {selectedImage && (
        <div
        style={{
            position: 'fixed', 
            top: '0', 
            left: '0',
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 10, 
            backdropFilter: 'blur(5px)', 
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            transition: 'transform 0.3s ease', 
          }}
        >
          <img
            src={selectedImage.src}
            alt="Imagem Selecionada"
            style={{
              width: 'auto', 
              height: '1000px', 
              borderRadius: '10px', 
            }}
          />
          <p style={{
              position: 'absolute',
              bottom: '20px',
              left: '0',
              width: '100%',
              padding: '5px',
              color: 'white',
              fontSize: '25px',
              textAlign: 'center',
              fontFamily: 'Futura',
            }}>{selectedImage.title}
          </p>
          {/* Ícone de Fechar no Canto Superior Direito */}
          <button
            onClick={handleClose}
            style={{
              position: 'absolute',
              top: '10px',
              right: '30px',
              background: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              border: 'none',
              borderRadius: '50%',
              width: '30px',
              height: '30px',
              fontSize: '18px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              zIndex: 25,
            }}
          >
            X
          </button>
        </div>
      )}
    </div>
  );
};

export default Produtos;
