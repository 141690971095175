import React from 'react'

import imgImalust from '../assets/img/ImalusitFoto.png';

const lightBrown = '#e9e6df';
    const redBtn = '#D0333A';
    const darkGreen = '#007961';
    //const darkRed = '#9E2141';



const contacto = () => {
    return (
        <div style={{ maxWidth: '1280px', margin: '0 auto' }}>
            <div style={{ width: '100%', height: '690px', padding: '0px 97px', margin: '0 auto', background: lightBrown, marginTop: '-50px' }}>
                <div className='my-5'>

                    <div className='row' style={{ justifyContent: 'center', alignItems: 'center', height: '100%', padding: '66px 0' }}>

                        <div className="col-md-6" style={{ border:`2px solid ${darkGreen}`, padding: '50px 100px',display: 'flex', flexDirection: 'column', alignItems: 'left', height: '556px', width: '448px', backgroundColor: 'white', justifyContent: 'center'}}>
                        <h2 style={{ fontFamily: 'Concord'}}>Contate-nos</h2>
                        <p  style={{color: darkGreen, fontFamily: 'Concord', fontSize: '18px', fontWeight: '400', lineHeight: '24px', marginTop: '10px'}}>Ficaremos encantados em fazer parte do teu projecto.</p>
                        <form>
                            <div className="form-group" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '10px' }}>
                            <label htmlFor="InputName" style={{color: darkGreen, fontFamily: 'Concord', fontSize: '18px', fontWeight: '400', lineHeight: '24px'}}>Nome</label>
                            <input type="text" className="form-control" id="InputName" aria-describedby="nameHelp" placeholder="" style={{fontFamily: 'Concord', fontSize: '18px', fontWeight: '400', lineHeight: '24px'}} />
                            
                            <label htmlFor="InputEmail1" style={{color: darkGreen, fontFamily: 'Concord', fontSize: '18px', fontWeight: '400', lineHeight: '24px', marginTop: '10px'}}>Email</label>
                            <input type="email" className="form-control" id="InputEmail1" aria-describedby="emailHelp" placeholder="" style={{fontFamily: 'Concord', fontSize: '18px', fontWeight: '400', lineHeight: '24px'}} />
                            
                            <label htmlFor="InputTelefone" style={{color: darkGreen, fontFamily: 'Concord', fontSize: '18px', fontWeight: '400', lineHeight: '24px', marginTop: '10px'}}>Telefone</label>
                            <input type="tel" className="form-control" id="InputTelefone" aria-describedby="phoneHelp" placeholder="" style={{fontFamily: 'Concord', fontSize: '18px', fontWeight: '400', lineHeight: '24px'}} />
                            
                            <label htmlFor="InputMensagem" style={{color: darkGreen, fontFamily: 'Concord', fontSize: '18px', fontWeight: '400', lineHeight: '24px', marginTop: '10px'}}>Assunto</label>
                            <textarea className="form-control" id="InputMensagem" rows="3" placeholder="Digite seu assunto aqui..." style={{fontFamily: 'Concord', fontSize: '18px', fontWeight: '400', lineHeight: '24px'}}></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary" style={{margin: '20px 0', backgroundColor: redBtn, border: 'none', borderRadius: '5px', padding: '0px 30px', color: '#fff', fontWeight: 'bold', fontSize: '16px', cursor: 'pointer'}}>Enviar</button>
                        </form>
                        </div>
                        
                        <div className="col-md-6 d-flex align-items-center justify-content-center" style={{paddingLeft: '0'}}>
                        <img src={imgImalust} alt="Formulário"   style={{ width: '558px', height: '556px'}}  />
                        </div>
                    
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default contacto;