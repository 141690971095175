import React, { useState } from "react";
import styled from 'styled-components'; 
import '../styles/home.css'
import Contatos from './Contacto';
import { productImages } from '../components/productImages';



import imgHome from '../assets/img/ImagenHome.jpeg';
import logoDominio from '../assets/img/LogoDomino.png';
import logoCeragni from '../assets/img/LogoRecer.png';
import logoCerteca from '../assets/img/LogoAleluia.png';
import logoPrimvs from '../assets/img/LogoPrimvs.png';
import triangleRedBG from '../assets/img/TrianguloRojoTransparencia.png';
import triangleWhiteBG from '../assets/img/TrianguloBlancoTransparencia.png';
import triangleGreenArrow from '../assets/img/flechaverde.png';
import triangleWhiteArrow from '../assets/img/flechablanca.png';
import circleExpositorTorneiras from '../assets/products/CircleExpositorTorneirasPk.jpg'
import expositorMg7760 from '../assets/products/circleExpositorMg7760.jpg'
import expositorSanitaFrente2 from '../assets/products/CircleExpositorSanitaFrente2.jpg'
import nextBtn from '../assets/img/nextBtn.png'
import imgBanho from '../assets/img/imalusitImagembanho.jpg'

const lightBrown = '#e9e6df';
const redBtn = '#D0333A';
const darkRed = '#9E2141';
const darkGreen = '#007961';





const Title = styled.h1`
  font-size: 50px;  
  font-weight: bold;
  font-family: 'Concord', sans-serif;
  margin-left: 138px;
  margin-right: 138px;
  margin-bottom: 50px;
`;


  const WhiteTitle = styled.h1`
  font-size: 50px;  
  color: white;
  font-weight: bold;
  font-family: 'Concord', sans-serif;
  margin-left: 138px;
  margin-right: 138px;
  margin-bottom: 50px;
`;


  const Paragraph = styled.p`
  font-size: 18px;
  font-family: 'Futura', sans-serif;
  color: black;
  margin-left: 138px;
  margin-right: 138px;
`;

const WhiteParagraph = styled.p`
  font-size: 20px; 
  color: white;
  font-family: 'Futura', sans-serif;
  margin-left: 138px;
  margin-right: 138px;
`;

  
  const Home = () => {

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [currentImageIndexFour, setCurrentImageIndexFour] = useState(0);


    const itemsPerPage = 4; // Número de itens visíveis por vez
    // Total de imagens no componente
    const totalImages = productImages.length;
  

    const handlePreviousFour = () => {
      setCurrentImageIndexFour((prevIndex) =>
        prevIndex === 0 ? 0 : prevIndex - 1 // Para no início
      );
    };
    
    const handleNextFour = () => {
      setCurrentImageIndexFour((prevIndex) =>
        prevIndex === totalImages - 1 ? 0 : prevIndex + 1 // Volta ao início no final
      );
    };
  
    const visibleImagesFour = productImages.slice(
      currentImageIndexFour,
      currentImageIndexFour + itemsPerPage
    );

    // Cálculo do índice inicial para o grid
    const startIndex = Math.max(
      0,
      Math.min(currentImageIndex - 4, totalImages - 8)
    );
  
    // Imagens visíveis no grid
    const visibleImages = productImages.slice(startIndex, startIndex + 8);
  
    // Função para avançar no carrossel item unico
    const handleNext = () => {
      if (currentImageIndex < totalImages - 1) {
        setCurrentImageIndex((prev) => prev + 1);
      }
    };
  
    // Função para voltar no carrossel
    const handlePrevious = () => {
      if (currentImageIndex > 0) {
        setCurrentImageIndex((prev) => prev - 1);
      }
    };


   

    return (
      <div className='divHome' style={{position: 'relative',  margin: '0 auto', padding: '0px', maxWidth: '1280px' }}>
        
        <section id="ImgHomeSection">
          <div style={{ maxWidth: '1280px', height: 'auto', padding: '0px', margin: '0 auto' }}>
            <img className='imgHome'  src={imgHome} alt='imgHome' style={{ maxWidth: '1280px', height: 'auto', padding: '0px', margin: '0 auto' }}/>
          </div>
        </section>

        <section id="TxtHomeSection">
          <div style={{ maxWidth: '1280px', height: '672px', padding: '50px 0', margin: '0 auto', backgroundColor: lightBrown, position: 'relative' }}>
            <img className='greenArrow' src={triangleGreenArrow} alt='imgHome2' style={{ width: 'auto', height: '100px', margin: 'auto', padding: '0px' }} />
            <img className='redTriangle'src={triangleRedBG} alt='imgHome2' style={{ width: 'auto', height: '602px', margin: 'auto', padding: '0px' }} />
            <button className='btnLearnMore' style={{backgroundColor: redBtn, border: 'none', borderRadius: '5px', padding: '0px 20px', color: 'white'}}>
              Saiba mais
            </button>

            <Title>
              Exposmos o seu<br />
              produto, melhoramos<br />
              sua imagem
            </Title>

            <Paragraph>
              A IMALUSIT - Indústria de Madeira Lusitana, Ltda é uma empresa com sede no Poreiro, a 2 km da cidade de Anadia, que
              desde 2007 se dedica exclusivamente à produção de displays (expositores) e showroom.
            </Paragraph>

            <Paragraph>
            Com excelente qualidade nos seus produtos, seja m construção ou acabamentos. cumrpimos rigorosmente os prazos
            estabelecidos nos projetos que se propõem.
            </Paragraph>

            <Paragraph>
              Os nossos produtos são pensados e fabricados de forma a teerem uma montagem de encaixe, simplificando o transporte e
              a colocação nos showrooms sem perder a principal função a visibilidade dos produtos expostos.
            </Paragraph>

            <Paragraph>
              Ouvimos, estudamos e aconselhamos os nossos clientes nas diversas vantagens que lhe proporcionem uma melhor 
              exposição e visibilidade.
            </Paragraph>

            <Paragraph>
            Reconhecemos o público como lideres, na qualidade, ética e empenho, nos serviços que prestamos.
            </Paragraph>

          </div>
        </section>

        <div className="circleBase circleGreen">
          <p>Produtos</p>
        </div>

        <section id="produtosSection">
            <div className='' style={{ maxWidth: '1280px', height: '1340px', padding: '50px 138px', margin: '0 auto' }}>
              <div style={{width: '100%', height: '670px', marginTop: '50px'}}>
                <div style={{ width: "100%", height: "550px", display: "flex" }}>
                  {/* Texto */}
                  <div style={{ flex: 1, }}>
                    <p style={{ fontSize: "25px" }}>
                      {productImages[currentImageIndex].title}
                    </p>
                  </div>

                  {/* Imagem central */}
                  <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center",  marginBottom: '150px' }}>
                  <img src={nextBtn} alt="nextBtn" onClick={handlePrevious} style={{maxHeight: '50px'}}/>
      
                    <img
                      src={productImages[currentImageIndex].src}
                      alt={productImages[currentImageIndex].alt}
                      style={{
                        width: "200px",
                        height: "auto",
                        border: "1px solid lightgrey",
      
                      }}
                    /> 
                    <img src={nextBtn} alt="nextBtn" onClick={handleNext} style={{maxHeight: '50px', rotate: "180deg"}}/>
                  </div>

                  {/* Grid de imagens */}
                  <div
                    style={{
                      flex: 1,
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 100px)",
                      gridTemplateRows: "repeat(4, 100px)",
                      gap: "20px",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    {visibleImages.map((image, index) => (
                      <img
                        key={image.id}
                        src={image.src}
                        alt={image.alt}
                        onClick={() => setCurrentImageIndex(startIndex + index)}
                        style={{
                          width: "100%", 
                          height: "100%", 
                          objectFit: "cover",
                          objectPosition: "center",
                          border: startIndex + index === currentImageIndex ? "3px solid green" : "none",
                          borderRadius: "8px",
                          boxSizing: "border-box",
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div> 
              
              <div className='' style={{ width: '100%', height: '550px',margin: '20px auto', justifyContent:'end'}} >


                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: '100%',  position: 'relative'}} >
                  <div style={{position: 'absolute', top: '0px', left: '50%', width: '800px', marginLeft: '-400px', marginTop: '-100px' }}>
                    <img className='greenArrow' src={triangleGreenArrow} alt='triangleGreenArrow' style={{ width: 'auto', height: '40px', margin: 'auto', padding: '0px', position:'absolute', top: '15px', left: '20px', zIndex: '1'}} />
                      <h3 style={{margin: '20px auto', textAlign: 'center', fontSize: '30px', fontFamily: 'Concord'}}>
                      Os nossos produtos possuem montagem simples, facilitando o transporte e a instalação, sem comprometer a visibilidade dos itens expostos.  
                      </h3>
                    </div>

                  <div style={{width: '300px', height: '100%', alignItems: 'center', justifyContent: 'center', textAlign: 'center', backgroundColor: lightBrown, borderRadius: '150px  150px  0 0', marginTop: '50px'}} >
                    <img className='' src={expositorSanitaFrente2} alt='expositorTorneiras' style={{backgroundColor: 'white',border: '3px darkGreen solid', width: '300px', height: '300px', margin: 'auto', borderRadius: '50%'}} />
                    <h3 style={{margin: '20px auto', fontSize: '25px'}}>
                      Expositor de Sanitas <br/>   
                    </h3>
                    <p style={{fontSize: '14px', fontFamily: 'Concord', padding: '0 10%', textAlign: 'start'}} >

                    </p>
                  </div>
                  <div>

                    <div style={{width: '250px', height: '450px', alignItems: 'center', justifyContent: 'center', textAlign: 'center', backgroundColor: lightBrown, borderRadius: '125px  125px  0 0',margin: 'auto', marginTop: '150px', paddingBottom: '10px'}} >
                      <img className='' src={circleExpositorTorneiras} alt='expositorTorneiras' style={{backgroundColor: 'white',border: '3px darkGreen solid', width: '250px', height: '250px', margin: 'auto', borderRadius: '50%'}} />
                      <h3 style={{margin: '20px auto', fontSize: '25px'}}>
                        Expositor de torneiras <br/>   
                      </h3>
                      <p style={{fontSize: '14px', fontFamily: 'Concord', padding: '0 10%', textAlign: 'start'}} >

                      </p>
                    </div>
                  </div>
                  <div style={{width: '300px', height: '100%', alignItems: 'center', justifyContent: 'center', textAlign: 'center', backgroundColor: lightBrown, borderRadius: '150px  150px  0 0', marginTop: '50px'}} >
                    <img className='' src={expositorMg7760} alt='expositorTorneiras' style={{backgroundColor: 'white',border: '3px darkGreen solid', width: '300px', height: '300px', margin: 'auto', borderRadius: '50%'}} />
                    <h3 style={{margin: '20px auto', fontSize: '25px'}}>
                      Expositor de Cerâmicos <br/>   
                    </h3>
                    <p style={{fontSize: '14px', fontFamily: 'Concord', padding: '0 10%', textAlign: 'start'}} >
      
                    </p>
                  </div>
                </div>
              </div>
          </div>
        </section>

        <section id="MaisVendidosSection">
        <div className="circleBase circleRed">
          <p>Mais Vendidos</p>
        </div>

          <div style={{ maxWidth: '1280px', height: '672px', padding: '70px 0', margin: '0 auto', backgroundColor: darkRed, position: 'relative' }}>
            <img className='whiteArrow' src={triangleWhiteArrow} alt='imgHome2' style={{ width: 'auto', height: '100px', margin: 'auto', padding: '0px' }} />
            <img className='redTriangle'src={triangleWhiteBG} alt='imgHome2' style={{ width: 'auto', height: '602px', margin: 'auto', padding: '0px' }} />
            <WhiteTitle>
              Parceria Personalizada<br />
              Para Máxima Visibilidade<br />
            </WhiteTitle>

            <WhiteParagraph>
              Trabalhamos em estreita colaboração com nossos clientes, ouvindo suas necessidades, estudando suas 
              exigencias e oferecendo aconcelhamentos especializado para garantir uma apresentação otimizada e com 
              máxima visibilidade para os produtos.
            </WhiteParagraph>
          </div>
        </section>


    
      
        <section id="ClientLogoSection">
          <div style={{ maxWidth: '1280px',  backgroundColor: lightBrown , height: '116px', margin: '0 auto'}}>
            <div className='row' style={{ justifyContent: 'space-between', alignItems: 'center', height: '100%', margin:'0 138px' }}>
              <img src={logoDominio} alt="logoDominio" style={{ width: 'auto', height: '40px', margin: '0', border: 'none' }} />
              <img src={logoCeragni} alt="logoCeragni" style={{ width: 'auto', height: '35px', margin: '0', border: 'none' }} />
              <img src={logoCerteca} alt="logoCerteca" style={{ width: 'auto', height: '40px', margin: '0', border: 'none' }} />
              <img src={logoPrimvs} alt="logoPrimvs" style={{ width: 'auto', height: '60px', margin: '0', border: 'none' }} />
            </div>
          </div>
        </section>


        <div style={{ width: '1280px', height: '980px', padding: '0 138px', margin: '0 auto' }}>
          <div className='txtImgBanho' style={{ width: '1000', height: '580px', padding: '0px', margin: '0', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div className='txtImgBanho' style={{ width: '500px', height: '300px', padding: '0px', margin: '0', display: 'flex',flexDirection: 'column',  justifyContent: 'start', alignItems: 'start'}}>
              <h1 style={{ fontSize: '50px', fontWeight: 'bold', textAlign: 'start', margin: '0', padding: '0', marginBottom: '10px' }}>
                  Descubra e<br/>
                  crie seu espaço
              </h1>
              <p style={{ fontSize: '20px', fontWeight: 'normal', textAlign: 'start', margin: '0', padding: '0', color: darkGreen }}>
                Os nossos produtos possuem montagem simples, facilitando o transporte e a instalação, sem comprometer a visibilidade dos itens expostos. 
              </p>
            </div>
            <img src={imgBanho} alt="imgBanho" style={{ width: '500px', height: 'auto', padding: '0px', margin: '0', display: 'flex', justifyContent: 'center', alignItems: 'center'}} />
          </div>

          <div className="carrocelProducts" style={{ maxWidth: '1000px', margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            
            {/* Botão de Navegação para Esquerda */}
            <img src={nextBtn} alt="nextBtn" onClick={handlePreviousFour} style={{maxHeight: '50px'}}/>
              <div className="carrocelProducts" style={{ maxWidth: '800px', margin: 'auto', cursor: 'pointer' }}>
                {/* Container do Carrossel */}
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(${itemsPerPage}, 200px)`,
                    gap: '25px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                  }}
                >
                  {visibleImagesFour.map((image) => (
                    <div
                      key={image.id}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '200px',
                        height: '240px',
                        overflow: 'hidden',
                      }}
                    >
                      <img
                        src={image.src}
                        alt={image.alt}
                        style={{
                          width: '200px',
                          height: '200px',
                          objectFit: 'cover',
                          borderRadius: '8px',
                        }}
                      />
                      <p style={{ marginTop: '8px', fontSize: '14px', textAlign: 'center' }}>
                        {image.title}
                      </p>
                    </div>
                ))}
              </div>
          </div>
          {/* Botão de Navegação para Direita */}
          <img src={nextBtn} alt="nextBtn" onClick={handleNextFour} style={{maxHeight: '50px', cursor: 'pointer',  rotate: "180deg"}}/>
          </div>
        </div>
        <div className="circleBase circleGreen">
          <p>Espaços</p>
        </div>
        

        <section id="contactSection">
          <Contatos />
        </section>

      </div>
    );
  }

export default Home;
