import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import iconeFace from '../assets/img/IconoFacebook.png';
import iconeInsta from '../assets/img/IconoInstagram.png';
import iconeLinkdin from '../assets/img/Linkedin.png';
import logoimaTel from '../assets/img/IconoTlf.png';
import iconeHome from '../assets/img/IconoHome.png';
import iconeEmail from '../assets/img/IconoCorreo.png';
import iconeWorld from '../assets/img/IconoUbicacion.png';

import logoimalusit from '../assets/img/LogoColor.png';

import '../styles/footer.css'

const darkRed = "#8B0000";
const lightBrown = "#e9e6df";

const Footer = () => {
  return (
    <div>
      {/* Seção principal do Footer */}
      <div style={{ backgroundColor: darkRed, maxWidth: '1280px', height: '220px', margin: '0 auto', padding: '0 40px' }}>  
            <div  style={{ backgroundColor: lightBrown, height: '220px', margin:'auto', padding: '0 100px' }}>
                <div className="row" style={{height: '100%'}}>

                
                    <div  style={{ backgroundColor: lightBrown, height: '220px', margin: 'auto'}}>
                        <div className="row align-items-center" style={{ height: '100%' }}>

                            {/* Coluna Esquerda */}
                            <div className="col d-flex flex-column justify-content-center align-items-start text-left">
                                <p className="mb-1">Empresa</p>
                                <p className="mb-1">Contactos</p>
                                <p className="mb-1">Clientes</p>
                            </div>
                            
                            {/* Coluna Central */}
                            <div className="col-md-auto text-center">
                            <img src={logoimalusit} alt="Logo Imalusit" className="mx-1" style={{ width: 'auto', height: '100px', marginBottom: '20px' }} />
                                <div className="d-flex justify-content-center align-items-center">
                                    <img src={iconeFace} alt="Logo Facebook" className="mx-1" style={{ width: '25px', height: 'auto' }} />
                                    <img src={iconeLinkdin} alt="Logo Instagram" className="mx-1" style={{ width: '25px', height: 'auto' }} />
                                    <img src={iconeInsta} alt="Logo X" className="mx-1" style={{ width: '25px', height: 'auto' }} />
                                    <p className="mb-0 ml-2" style={{ fontSize: '16px', marginLeft: '10px' }}>2024 - Todos os direitos reservados.</p>
                                </div>
                            </div>

                            {/* Coluna Direita */}
                            <div className="col d-flex flex-column align-items-md-end text-md-right justify-content-center">
                                <div className="d-flex align-items-center my-1"> {/* Reduzido my-2 para my-1 */}
                                    <p className="mb-0" style={{ marginRight: '5px' }}>Telefone: (XX) XXXX-XXXX</p>
                                    <img src={logoimaTel} alt="Telefone" className="mx-1" style={{ width: '25px', height: 'auto' }} />
                                </div>
                            <div className="d-flex align-items-center my-1"> {/* Reduzido my-2 para my-1 */}
                                <p className="mb-0" style={{ marginRight: '5px' }}>Endereço: Rua Exemplo, 123</p>
                                <img src={iconeHome} alt="Endereço" className="mx-1" style={{ width: '25px', height: 'auto' }} />
                            </div>
                                <div className="d-flex align-items-center my-1"> {/* Reduzido my-2 para my-1 */}
                                    <p className="mb-0" style={{ marginRight: '5px' }}>Email: contato@empresa.com</p>
                                    <img src={iconeEmail} alt="Email" className="mx-1" style={{ width: '25px', height: 'auto' }} />
                                </div>
                            <div className="d-flex align-items-center my-1"> {/* Reduzido my-2 para my-1 */}
                                <p className="mb-0" style={{ marginRight: '5px' }}>Website: www.empresa.com</p>
                                <img src={iconeWorld} alt="Website" className="mx-1" style={{ width: '25px', height: 'auto' }} />
                            </div>
                        </div>

                    </div>
                </div>


            </div> 
        </div>
    </div>
    
    {/* Seção inferior do Footer */}
    <div className="footer-bottom" style={{ backgroundColor: lightBrown, maxWidth: '1280px', height:'120px', margin: '0 auto'}}>
      </div>
    </div>
  );
}

export default Footer;
