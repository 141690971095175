import React from 'react';

import expositorA6 from '../assets/products/expositorA6.jpg';
import expositorA6Evolution from '../assets/products/expositorA6Evolution.jpg';
import expositorCeramicaPosted from '../assets/products/expositorCeramicaPosted.jpg';
import expositorLavaLoica from '../assets/products/expositorLavaLoica.jpg';
import expositorMadeira2 from '../assets/products/expositorMadeira2.jpg';
import expositorMg7760 from '../assets/products/expositorMg7760.jpg';
import expositorPlaytile from '../assets/products/expositorPlaytile.jpg';
import expositorWood from '../assets/products/expositorWood.jpg';
import img_0544a from '../assets/products/img_0544a.jpg';
import img_0660 from '../assets/products/img_0660.jpg';
import img_0661 from '../assets/products/img_0661.jpg';
import img_0661a from '../assets/products/img_0661a.jpg';
import img_0668 from '../assets/products/img_0668.jpg';
import img_0675a from '../assets/products/img_0675a.jpg';
import img_0679 from '../assets/products/img_0679.jpg';
import img_0679a from '../assets/products/img_0679a.jpg';
import img_0680a from '../assets/products/img_0680a.jpg';
import img_0731 from '../assets/products/img_0731.jpg';
import img_0731a from '../assets/products/img_0731a.jpg';
import img_0767 from '../assets/products/img_0767.jpg';
import img_1063 from '../assets/products/img_1063.jpg';
import img_1338 from '../assets/products/img_1338.jpg';
import img_1339 from '../assets/products/img_1339.jpg';
import img_1394 from '../assets/products/img_1394.jpg';
import space from '../assets/products/space.jpg';






export const productImages = [
    { id: 1, src: expositorA6, alt: 'Produto 1', title: 'Expositor de Cerâmicos' },
    { id: 2, src: expositorA6Evolution, alt: 'Produto 2', title: 'Expositor de Cerâmicos' },
    { id: 3, src: expositorCeramicaPosted, alt: 'Produto 3', title: 'Expositor de Cerâmicos' },
    { id: 4, src: expositorLavaLoica, alt: 'Produto 4', title: 'Expositor Lava Louça' },
    { id: 5, src: expositorMadeira2, alt: 'Produto 5', title: 'Expositor de Amostras' },
    { id: 6, src: expositorMg7760, alt: 'Produto 6', title: 'Expositor de Cerâmicos' },
    { id: 7, src: expositorPlaytile, alt: 'Produto 7',title: 'Expositor de Cerâmicos' },
    { id: 8, src: expositorWood, alt: 'Produto 12', title:  'Expositor de Madeiras' },
    { id: 9, src: img_0544a, alt: 'Produto 16', title: 'Expositor de Cerâmicos' },
    { id: 10, src: img_0660, alt: 'Produto 17', title: 'Expositor de Central' },
    { id: 11, src: img_0661, alt: 'Produto 18', title: 'Expositor de Ilha' },
    { id: 12, src: img_0661a, alt: 'Produto 19', title: 'Expositor de Ilha' },
    { id: 13, src: img_0668, alt: 'Produto 20', title: 'Expositores Troneiras' },
    { id: 14, src: img_0675a, alt: 'Produto 21', title: 'Parede de Exposição' },
    { id: 15, src: img_0679, alt: 'Produto 22', title: 'Ilha de Exposição' },
    { id: 16, src: img_0679a, alt: 'Produto 23', title: 'Expositor Sanita' },
    { id: 17, src: img_0680a, alt: 'Produto 24', title: 'Expositor Sanita' },
    { id: 18, src: img_0731, alt: 'Produto 29' , title: 'Expositor Sanita'},
    { id: 19, src: img_0731a, alt: 'Produto 30', title: 'Expositor Sanita' },
    { id: 20, src: img_0767, alt: 'Produto 31' , title: 'Expositor Sanita'},
    { id: 21, src: img_1063, alt: 'Produto 32', title: 'Expositor de Cerâmicos'},
    { id: 22, src: img_1338, alt: 'Produto 38' , title: 'Showrom de Cerâmicos'},
    { id: 23, src: img_1339, alt: 'Produto 39', title: 'Showrom de Cerâmicos com iluminação' },
    { id: 24, src: img_1394, alt: 'Produto 40', title: 'Expositor de Cerâmicos' },
    { id: 25, src: space, alt: 'Produto 43', title: 'Space' },
  ];



const ProductImage = ({ src, alt, onClick }) => (
  <div onClick={onClick} style={{ cursor: 'pointer', margin: '10px' }}>
    <img src={src} alt={alt} title={String} style={{ width: '295px', height: 'auto', borderRadius: '5px' }} />
  </div>
);

export default ProductImage;